import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { Form, Typography } from 'antd';
import moment from 'moment';
import { Div } from '../../framework';
import { activityActionType } from '../../helper/constants';
import { Activity, Applicant, Schedule } from '../../helper/type';
import {
  HirerEditCastingPayload,
  HirerInviteCastingPayload,
  useActivityService,
} from '../../service/activity.service';
import { useJobService } from '../../service/job.service';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import {
  setHirerSchedules,
  setApplicants as setGlobalApplicants,
  setInvitationInfo,
  setActivitiesMultiple,
} from '../../store/hirer.slice';
import InviteCastingForm, {
  InviteCastingFormValues,
} from '../Form/InviteCastingForm/InviteCastingForm';
import PromptInviteOther, {
  PromptInviteOtherFormValues,
} from '../Form/InviteCastingForm/PromptInviteOther';

import './InviteCastingModal.scss';

const { Title, Text } = Typography;

type ModalContext = {
  mode: 'new' | 'edit';
  multipleInvite: boolean;
  hirerId: string;
  roleId: string;
  projectId: string;
  roleName: string;
  projectTitle: string;
  applicant: Applicant;
  invitation?: Activity;
};

const InviteCastingModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const activityService = useActivityService();
  const jobService = useJobService();
  const { modal } = useSelector((state: RootState) => state.app);
  const { mappedApplicants, invitationInfo, mappedActivities } = useSelector(
    (state: RootState) => state.hirer
  );

  const [invSent, setInvSent] = useState(false);
  const [sentInvPayload, setSentInvPayload] = useState<
    HirerInviteCastingPayload | HirerEditCastingPayload
  >();
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const { context } = modal.config as { context: ModalContext };
  const {
    mode,
    multipleInvite,
    hirerId,
    roleId,
    projectId,
    roleName,
    projectTitle,
    applicant: currentApplicant,
    invitation,
  } = context;

  const { applicants, hasLoadOtherApplicants } = mappedApplicants[roleId] ?? {};

  const getApplicantInvitation = (talentId: string) => {
    if (roleId in mappedActivities && talentId in mappedActivities[roleId]) {
      const inv = mappedActivities[roleId][talentId].find(
        (act: Activity) =>
          act.actionType === activityActionType.casting && act.replyStatus
      );
      return inv;
    }
  };
  const isAllowInvite = (applicant: Applicant) => {
    const { talentId, status } = applicant;
    if (['confirmed', 'rejected', 'withdrawn'].includes(status)) return false;

    const isInvited = getApplicantInvitation(talentId);
    return !isInvited;
  };
  const otherApplicantDropdownItems = useMemo(() => {
    const applicants = mappedApplicants[roleId]?.applicants ?? [];
    if (mode === 'edit') {
      const items = applicants.filter((other) => {
        const otherInv = getApplicantInvitation(other.talentId);

        return (
          otherInv != undefined &&
          otherInv.castingType === invitation?.castingType &&
          other.talentId !== currentApplicant?.talentId
        );
      });
      const selected = items.map((item) => item.talentId);
      return { applicants: items, defaultSelected: selected };
    }

    const items = applicants.filter(
      (other) =>
        other.status === 'shortlisted' &&
        isAllowInvite(other) &&
        other.talentId !== currentApplicant?.talentId
    );

    const selected = items.map((item) => item.talentId);
    return { applicants: items, defaultSelected: selected };
  }, [mappedApplicants[roleId], mappedActivities[roleId]]);

  useEffect(() => {
    (async () => {
      if (!mappedApplicants[roleId]) {
        const data = await jobService.getApplicantsByRole(roleId, true);
        dispatch(
          setGlobalApplicants({
            roleId,
            applicants: data,
            hasLoadOtherApplicants: false,
            lastFetchTimestamp: new Date().toISOString(),
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (mode === 'edit' && invitation) {
        const {
          castingBrief,
          virtualMeetingLink,
          picContact,
          picContactPrefix,
          castingLocation,
          castingLocationLng,
          castingLocationLat,
          submitDeadline,
        } = invitation;
        form.setFieldsValue({
          castingBrief,
          virtualMeetingLink,
          picContact,
          picContactPrefix,
          castingLocation,
          castingLocationLng,
          castingLocationLat,
          submitDeadline: submitDeadline ? moment(submitDeadline) : undefined,
        });
      }

      if (mode === 'new' && invitationInfo) {
        const { submitDeadline, scheduleId } = invitationInfo;
        const today = new Date().toISOString();
        form.setFieldsValue({
          ...invitationInfo,
          submitDeadline:
            submitDeadline && submitDeadline > today
              ? moment(submitDeadline)
              : undefined,
        });

        if (scheduleId) await getSchedules();
      }
    })();
  }, []);

  useEffect(() => {
    if (!invSent) return;

    if (otherApplicantDropdownItems.applicants.length === 0) {
      closeSelf();
    }
  }, [invSent, otherApplicantDropdownItems.applicants]);

  const getSchedules = async () => {
    const result: Schedule[] = await activityService.listHirerSchedules(
      hirerId
    );
    result.forEach((sch) =>
      dispatch(setHirerSchedules({ scheduleId: sch.scheduleId, schedule: sch }))
    );
  };

  const handleApplicantDropdownVisibleChange = async (open: boolean) => {
    if (!open) return;

    if (!mappedApplicants[roleId]) {
      const data = await jobService.getApplicantsByRole(roleId, true);
      dispatch(
        setGlobalApplicants({
          roleId,
          applicants: data,
          hasLoadOtherApplicants: false,
          lastFetchTimestamp: new Date().toISOString(),
        })
      );
    }
  };

  const handleLoadMoreApplicant = async () => {
    if (hasLoadOtherApplicants) return;

    const data = await jobService.getApplicantsByRole(roleId, false);
    dispatch(
      setGlobalApplicants({
        roleId,
        applicants: applicants.concat(data),
        hasLoadOtherApplicants: true,
        lastFetchTimestamp: new Date().toISOString(),
      })
    );

    return data;
  };

  const sendInvitation = async (payload: HirerInviteCastingPayload) => {
    try {
      await activityService.hirerInviteCasting(payload);
      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.data?.code === 'NO_MORE_SLOTS') {
        setError(
          'Not enough schedule slots for all invited talents to choose, please open up more schedule slots before sending invitation.'
        );
      }

      return false;
    }
  };

  const editInvitation = async (
    talentId: string,
    payload: Omit<HirerEditCastingPayload, 'activityId' | 'talentId'>
  ) => {
    if (mode !== 'edit') return false;

    const inv = getApplicantInvitation(talentId);
    if (!inv) return false;

    try {
      const { updateLog = [], ...previousVersion } = inv;
      await activityService.hirerEditCasting({
        activityId: inv.activityId,
        talentId,
        ...payload,
        updateLog: [JSON.stringify(previousVersion), ...updateLog],
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  const loadTalentActivity = async (talentIds: string[]) => {
    const updatedActivities: Record<string, Activity[]> = {};
    const tasks = talentIds.map((talentId) =>
      (async () => {
        const { data: result } = await activityService.listRoleTalentActivity({
          roleId,
          talentId,
          isInterruptive: true,
        });
        updatedActivities[talentId] = result;
      })()
    );
    await Promise.all(tasks);

    dispatch(
      setActivitiesMultiple({
        roleId,
        talentActivities: updatedActivities,
      })
    );
  };

  const onFinishMainForm = async (values: InviteCastingFormValues) => {
    const invitedTalents: HirerInviteCastingPayload['talents'] = [];
    const { selectedApplicants, ...info } = values;
    if (multipleInvite) {
      selectedApplicants?.forEach((talentId) => {
        const {
          id: appId,
          phoneNumber,
          phoneNumberPrefix,
        } = applicants.find((ap) => ap.talentId === talentId) ?? {};
        invitedTalents.push({
          talentId,
          applicationId: appId || talentId,
          talentPhoneNumber: phoneNumber,
          talentPhoneNumberPrefix: phoneNumberPrefix,
        });
      });
    } else {
      invitedTalents.push({
        talentId: currentApplicant.talentId,
        applicationId: currentApplicant.id,
        talentPhoneNumber: currentApplicant.phoneNumber,
        talentPhoneNumberPrefix: currentApplicant.phoneNumberPrefix,
      });
    }

    let submitDeadline;
    if (info.castingType === 'self') {
      submitDeadline = info.submitDeadline.endOf('D').toISOString();
    }

    if (mode === 'new')
      dispatch(setInvitationInfo({ ...info, submitDeadline }));

    let invPayload;
    let successSent;
    if (mode === 'edit' && invitation) {
      const { duration, scheduleId, castingType, ...editInfo } = info as any;

      invPayload = {
        hirerId,
        roleId,
        ...editInfo,
        castingType,
        submitDeadline,
      };
      successSent = await editInvitation(currentApplicant.talentId, invPayload);
    } else {
      invPayload = {
        hirerId,
        roleId,
        talents: invitedTalents,
        roleName,
        projectTitle,
        projectId,
        actionType: activityActionType.casting,
        ...info,
        submitDeadline,
      };
      successSent = await sendInvitation(invPayload);
    }
    if (!successSent) return;

    await loadTalentActivity(invitedTalents.map(({ talentId }) => talentId));

    if (
      !multipleInvite &&
      ((mode === 'new' && currentApplicant.status === 'shortlisted') ||
        mode === 'edit')
    ) {
      setInvSent(true);
      setSentInvPayload(invPayload);
    } else {
      closeSelf();
    }
  };

  const onFinishOther = async ({
    selectedApplicants,
  }: PromptInviteOtherFormValues) => {
    if (!sentInvPayload) return;

    if (mode === 'edit') {
      const editTasks = selectedApplicants.map((talentId) =>
        editInvitation(talentId, sentInvPayload as HirerEditCastingPayload)
      );
      const successSent = (await Promise.all(editTasks)).every(
        (success) => success
      );

      await loadTalentActivity(selectedApplicants);

      if (!successSent) {
        setError('Unable to update invitation info at the moment.');
        return;
      }
    } else {
      for (let i = 0; i < selectedApplicants.length; i += 12) {
        const batch: HirerInviteCastingPayload['talents'] = selectedApplicants
          .slice(i, i + 12)
          .map((talentId) => {
            const {
              id: applicationId,
              phoneNumber,
              phoneNumberPrefix,
            } = applicants.find((ap) => ap.talentId === talentId) ?? {};
            return {
              talentId,
              applicationId: applicationId || '',
              talentPhoneNumber: phoneNumber || '',
              talentPhoneNumberPrefix: phoneNumberPrefix || '',
            };
          });
        const successSent = await sendInvitation({
          ...(sentInvPayload as HirerInviteCastingPayload),
          talents: batch,
        });
        await loadTalentActivity(batch.map(({ talentId }) => talentId));

        if (!successSent) return;
      }
    }

    closeSelf();
  };

  const renderDescription = () => {
    if (mode === 'edit' && !invSent) {
      return (
        <Text>
          Here you can edit info Casting invitation that previouly sent to{' '}
          <span className='bold'>{currentApplicant.name}</span>.
          <br />
          <Text type='danger'>*</Text> Kindly be aware that upon saving, the
          applicant will promptly receive a notification regarding any
          modifications made to the invitation.
        </Text>
      );
    }

    if (mode === 'edit' && invSent) {
      return (
        <Text>
          Do you wish to modify the invitation details for other invited
          applicants as well?
        </Text>
      );
    }

    if (mode === 'new' && !invSent) {
      return (
        <Text>
          Here you can send an invitation to{' '}
          <span className='bold'>
            {multipleInvite ? 'applicants' : currentApplicant.name}
          </span>{' '}
          for a Casting session on <span className='bold'>{roleName}</span> of{' '}
          <span className='bold'>{projectTitle}</span>.
        </Text>
      );
    }

    if (mode === 'new' && invSent && !multipleInvite) {
      return (
        <Text>
          Do you want to send this casting invitation to the other shortlisted
          talents?
        </Text>
      );
    }
  };

  const closeSelf = () => dispatch(closeModal());

  return (
    <Div className='invite-casting-modal'>
      {mode === 'edit' ? (
        <Title level={4}>Edit Casting Invitation</Title>
      ) : (
        <Title level={4}>Invite talent for Casting</Title>
      )}
      <Div mb='l'>{renderDescription()}</Div>
      <Div mb='l'>
        {!invSent && (
          <InviteCastingForm
            mode={mode}
            initialValues={invitation}
            hirerId={hirerId}
            roleId={roleId}
            multipleInvite={multipleInvite}
            castingType={
              mode === 'new'
                ? invitationInfo.castingType ?? 'virtual'
                : invitation?.castingType
            }
            form={form}
            onFinish={onFinishMainForm}
            onLoadSchedules={getSchedules}
            onApplicantDropdownVisibleChange={
              handleApplicantDropdownVisibleChange
            }
            applicants={mappedApplicants[roleId]?.applicants ?? []}
            onLoadMoreApplicant={handleLoadMoreApplicant}
            isAllowInvite={isAllowInvite}
            onCancel={closeSelf}
            scheduleError={error}
          />
        )}
        {invSent && !multipleInvite && (
          <PromptInviteOther
            mode={mode}
            {...otherApplicantDropdownItems}
            hasLoadOtherApplicants={hasLoadOtherApplicants}
            onApplicantDropdownVisibleChange={
              handleApplicantDropdownVisibleChange
            }
            onLoadMoreApplicant={handleLoadMoreApplicant}
            onFinish={onFinishOther}
            onCancel={closeSelf}
            error={error}
          />
        )}
      </Div>
    </Div>
  );
};

export default InviteCastingModal;
