import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Radio, Space, Typography } from 'antd';
import { Div } from '../../framework';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import { useJobService } from '../../service/job.service';
import { useActivityService } from '../../service/activity.service';

import './TalentWithdrawApplicationModal.scss';

const { Title } = Typography;

type ModalContext = {
  hirerId: string;
  roleId: string;
  applicationId: string;
  projectId: string;
  projectTitle: string;
  roleName: string;
  applicantStatus: string;
};

type FormValue = {
  withdrawReason: string;
  otherReason?: string;
};

const TalentWithdrawApplicationModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const jobService = useJobService();
  const activityService = useActivityService();
  const { modal } = useSelector((state: RootState) => state.app);
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { context } = modal.config as {
    context: ModalContext;
  };

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;
  const talentId = authUser.username || (isImpersonator ? impersonateId : '');

  const { projectTitle, roleName, roleId, applicantStatus } = context;
  const isConfirmed = applicantStatus === 'confirmed';

  const [otherReason, setOtherReason] = useState(false);

  const handleWithdraw = async (value: FormValue) => {
    const withdrawReason: string =
      value.withdrawReason === 'others' && value.otherReason
        ? value.otherReason
        : value.withdrawReason;
    await jobService.updateApplicantStatus({
      roleId,
      talentId,
      status: 'withdrawn',
    });
    const { applicantStatus, ...withdrawPayload } = context;
    await activityService.talentWithdrawApplication(talentId, {
      ...withdrawPayload,
      withdrawReason,
    });
    dispatch(closeModal());
  };

  return (
    <Div className='talent-withdraw-application-modal'>
      <Title level={4}>Confirm application withdrawal?</Title>
      <Div mb='l'>
        <Div>
          Are you sure you want to withdraw application of{' '}
          <span className='bold'>
            {projectTitle} - {roleName}
          </span>
          ?
        </Div>
        {isConfirmed && (
          <Div className='text-danger'>
            Warning: Repeatedly withdrawing your application after confirmed for
            shooting could reduce the likelihood of getting selected in the
            future.
          </Div>
        )}
      </Div>
      <Div mb='m'>
        <Form onFinish={handleWithdraw} layout='vertical'>
          <Form.Item
            name='withdrawReason'
            label='Withdrawal reason'
            rules={[
              {
                required: true,
                message: 'Please tell us the reason of your withdrawal.',
              },
            ]}
            className='reason-list'
          >
            <Radio.Group
              optionType='default'
              onChange={(e) => setOtherReason(e.target.value === 'others')}
            >
              <Space direction='vertical'>
                {[
                  'The shooting date conflict with my agenda',
                  'Illness / Sick',
                ].map((reason, idx) => (
                  <Radio.Button key={idx} value={reason}>
                    {reason}
                  </Radio.Button>
                ))}
                <Radio.Button value='others'>Others</Radio.Button>
              </Space>
            </Radio.Group>
          </Form.Item>
          {otherReason && (
            <Form.Item
              name='otherReason'
              rules={[
                {
                  required: true,
                  message: 'Please tell us the reason of your withdrawal.',
                },
              ]}
            >
              <Input.TextArea
                placeholder='Tell us why you want to withdraw.'
                showCount
                autoSize={{
                  minRows: 3,
                  maxRows: 4,
                }}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              block
              type='primary'
              danger
              htmlType='submit'
              className='btn-confirm-withdraw'
            >
              {isConfirmed
                ? 'Yes, I understand and would like to withdraw my application'
                : 'Confirm Withdrawal'}
            </Button>
            <Button block onClick={() => dispatch(closeModal())}>
              No, let me reconsider
            </Button>
          </Form.Item>
        </Form>
      </Div>
    </Div>
  );
};

export default TalentWithdrawApplicationModal;
