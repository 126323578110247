import React, { FC } from 'react';
import { Input, Select } from 'antd';
import { internationalCodes } from '../../../constant/region';

export type PhoneNumberInputValue = {
  phoneNumber: string;
  phoneNumberPrefix: string;
};

export const phoneNumberInputValidator = ({
  emptyError = 'Please input your contact number',
  invalidError = "This phone number doesn't seems right",
}: {
  emptyError?: string;
  invalidError?: string;
}) => {
  return (_: unknown, value: PhoneNumberInputValue) => {
    if (!value || !value.phoneNumber || !value.phoneNumberPrefix) {
      return Promise.reject(emptyError);
    }

    const validRegex =
      value.phoneNumberPrefix === '+60'
        ? /^(0?1)[0-46-9]-*[0-9]{7,8}$/
        : /^[0-9]{6,12}$/;
    if (!value.phoneNumber.match(validRegex)) {
      return Promise.reject(invalidError);
    }

    return Promise.resolve();
  };
};

type Props = {
  value?: PhoneNumberInputValue;
  onChange?: (phoneNumber: PhoneNumberInputValue) => void;
};

const { Option } = Select;

const PhoneNumberInput: FC<Props> = ({ value, onChange }) => {
  const handleValueChange = ({
    phoneNumber = value?.phoneNumber,
    phoneNumberPrefix = value?.phoneNumberPrefix,
  }: Partial<PhoneNumberInputValue>) => {
    onChange?.call(null, {
      phoneNumber: phoneNumber ?? '',
      phoneNumberPrefix: phoneNumberPrefix ?? '',
    });
  };

  return (
    <Input
      type='number'
      value={value?.phoneNumber}
      onChange={(event) =>
        handleValueChange({ phoneNumber: event.target.value })
      }
      addonBefore={
        <Select
          style={{ width: 150 }}
          onChange={(phoneNumberPrefix) =>
            handleValueChange({ phoneNumberPrefix })
          }
          value={value?.phoneNumberPrefix || '+60'}
        >
          {internationalCodes.map((code) => (
            <Option
              key={`${code.prefix} (${code.name})`}
              value={code.prefix}
            >{`${code.prefix} (${code.name})`}</Option>
          ))}
        </Select>
      }
    />
  );
};

export default PhoneNumberInput;
