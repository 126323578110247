import React, { FunctionComponent } from 'react';
import { Div } from '../../framework';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './PromptEmailVerificationModal.scss';
import { getEmailProviderUrl } from '../../helper/getEmailProviderUrl';
import * as analyticService from '../../service/analytic.service';

const { Paragraph, Title, Link } = Typography;

const PromptEmailVerificationModal: FunctionComponent = () => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const emailLink = getEmailProviderUrl(authUser.email, 'verify your account');

  const handleClick = () => {
    analyticService.trackEvent('redirect to verification email', {
      src: 'modal',
    });
  };

  return (
    <Div
      style={{
        height: '30vh',
      }}
    >
      <Title level={4}>
        Congratulations on completing your talent profile!
      </Title>
      <Paragraph>
        In order to fully activate your account and enjoy all of our services,
        we kindly ask that you{' '}
        {emailLink ? (
          <Link href={emailLink} target='_blank' onClick={handleClick}>
            verify your email address
          </Link>
        ) : (
          'verify your email address'
        )}
        .
      </Paragraph>
      <Paragraph>
        Please click on the verification link we have sent to{' '}
        <Link>{authUser.email}</Link>. If you do not see the email, be sure to
        check your spam folder.
      </Paragraph>
    </Div>
  );
};

export default PromptEmailVerificationModal;
